
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { config } from "@/core/helpers/config";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import "viewerjs/dist/viewer.css";

export default defineComponent({
  name: "finance-credit-note-motor-insurance-policy-information",
  components: {
    GoBackButton
  },
  data() {
    return {
      policySummary: {},
      policies: [],
      receipts: [],
      invoices: [],
      publicId: this.$route.params.publicId,
      imageURL: ""
    };
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");
      setCurrentPageBreadcrumbs("Policy Information", ["Policy"]);
    });

    /**
     * Reset config
     * @param event
     */
    const reset = (event) => {
      event.preventDefault();
      // remove existing saved config
      localStorage.removeItem("config");
      window.location.reload();
    };

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    return {
      tabIndex,
      config,
      reset,
      setActiveTab
    };
  },
  created() {
    // Get policy information
    this.getPolicyInformation();

    // Policy images route
    this.imageURL = variables.POLICY_IMAGES_ROUTE;

    //Set page title
    document.title = "Policy Information | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    getPolicyInformation() {
      const router = useRouter();

      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.FINANCE_POLICY_DETAILS_ROUTE}/${this.publicId}`
        )
          .then(({ data }) => {
            //Assign data to variables
            this.policySummary = data.data.policySummary;
            this.policies = data.data.items;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
            router.go(-1);
          });
      }
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/no-photo-available.png";
    }
  }
});
